export const calculateProgress = (start: string, end: string) => {
	const startDate = new Date(start).getTime();
	const endDate = new Date(end).getTime();
	const currentDate = new Date().getTime();

	const totalDuration = endDate - startDate;
	const elapsedDuration = currentDate - startDate;
	const currentProgress = (elapsedDuration / totalDuration) * 100;

	let percentage;

	if (currentDate < startDate) {
		// Kelas belum dimulai
		percentage = 0;
	} else if (currentDate > endDate) {
		// Kelas sudah selesai
		percentage = 100;
	} else {
		// Kelas sedang berlangsung
		percentage = currentProgress;
	}

	return percentage;
};

export const formatBytes = (bytes: any, decimals = 2) => {
	if (!+bytes) return "0 Bytes";

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = [
		"Bytes",
		"KiB",
		"MiB",
		"GiB",
		"TiB",
		"PiB",
		"EiB",
		"ZiB",
		"YiB",
	];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const isValidUrl = (s: string) => {
	var expression =
		/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
	var regex = new RegExp(expression);

	return s?.match(regex);
};

export const rendertext = (text: string) => {
	const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
	return text.replace(
		reg,
		`<a class="hover:text-blue-600 duration-300 underline hyphens-auto break-words" href='$1$2' target='_blank'>$1$2</a>`,
	);
};

{
	/*import { type_doc, type_jpg, type_pdf, type_png, type_ppt } from "~/assets";

export function getFileIcon(fileType) {
  const iconMapping = {
    doc: type_doc,
    jpg: type_jpg,
    pdf: type_pdf,
    png: type_png,
    ppt: type_ppt,
  };

  return iconMapping[fileType];
}

export function getFileType(fileName) {
  const [, extension] = fileName.split(".");
  const fileType = extension.toLowerCase();

  const fileTypeMapping = {
    png: "png",
    jpg: "jpg",
    jpeg: "jpg",
    gif: "gif",
    doc: "doc",
    docx: "doc",
    pdf: "pdf",
  };

  return fileTypeMapping[fileType] || "unknown";
}*/
}

const customColors = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099"];
let nameColors = {};

export const generateRandomColor = (name: string) => {
	if (nameColors[name]) {
		return nameColors[name];
	}

	const randomColor =
		customColors[Math.floor(Math.random() * customColors.length)];

	const _nameColors = {
		...nameColors,
		[name]: randomColor,
	};
	nameColors = _nameColors;
	//setNameColors(_nameColors);

	return randomColor;
};

export const handleFileChangeClass = (
	event: { target: { files: null } },
	fileClass: Blob,
	setFileClass: (arg0: any[]) => void,
) => {
	const file = event.target.files ?? null;
	if (file) {
		const filesArray = Array.from(file);
		const _file = [...fileClass, ...filesArray];
		setFileClass(_file);
	}
};

export const handleDropClass = (event, fileClass, setFileClass) => {
	event.preventDefault();

	const file = event.dataTransfer.files ?? null;
	if (file) {
		const filesArray = Array.from(file);
		const _file = [...fileClass, ...filesArray];
		setFileClass(_file);
	}
};

export const handleFileChatInput = (event, setter) => {
	event.preventDefault();

	const file =
		event?.dataTransfer?.files?.[0] ?? event?.target?.files?.[0] ?? null;

	if (file) {
		const reader = new FileReader();
		reader.onloadend = () => {
			setter({ file, preview: reader.result });
		};

		if (file.type.startsWith("image/")) {
			reader.readAsDataURL(file);
		} else {
			reader.readAsText(file);
		}
	}
};

export const handleDragOver = (event: any) => {
	event.preventDefault();
};

export const handleDragEnter = (event: any) => {
	event.preventDefault();
};

export const handleDragLeave = (event: any) => {
	event.preventDefault();
};

export const handleButtonClick = (fileInputRef: {
	current: { click: () => void };
}) => {
	if (fileInputRef.current) {
		fileInputRef.current.click();
	}
};

export function parseIfString(input: string) {
	if (typeof input === "string") {
		try {
			return JSON.parse(input);
		} catch (error) {
			return null;
		}
	} else if (typeof input === "object" && input !== null) {
		return input;
	}
	return input;
}

export function sliceName(inputString: string, count?: number) {
	const isCount = count ? count : 17;
	if (inputString && inputString?.length > isCount) {
		return `${inputString.slice(0, isCount)}...`;
	}
	return inputString;
}

const CACHE_EXPIRATION_TIME = 5 * 60 * 1000; // 10 menit dalam milidetik

// Menyimpan data ke localStorage dengan timestamp
export const setCache = (CACHE_KEY: string, data: any) => {
	const timestamp = new Date().getTime(); // Waktu saat ini
	const expirationTime = timestamp + CACHE_EXPIRATION_TIME; // Waktu kadaluarsa
	localStorage.setItem(CACHE_KEY, JSON.stringify({ data, expirationTime }));
};

export const getCache = (CACHE_KEY: string) => {
	const cached = localStorage.getItem(CACHE_KEY);
	if (!cached) return null;

	const { data, expirationTime } = JSON.parse(cached);
	const currentTime = new Date().getTime();

	if (currentTime > expirationTime) {
		localStorage.removeItem(CACHE_KEY);
		return null;
	}

	return data;
};

export function parseJsonStringify(input: string) {
	if (input && typeof input === "string") {
		try {
			return JSON.parse(input);
		} catch (error) {
			return null;
		}
	}
	return input;
}
